var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-gufi-previews-wrapper" },
    _vm._l(_vm.results, function(result, index) {
      return _c("preview", {
        key: result.id,
        attrs: { result: result, isActive: result.id === _vm.activeResult.id },
        on: { onResultClicked: _vm.onResultClicked }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }