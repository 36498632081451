import { render, staticRenderFns } from "./StaffHighlight.vue?vue&type=template&id=266a7a46&"
import script from "./StaffHighlight.vue?vue&type=script&lang=ts&"
export * from "./StaffHighlight.vue?vue&type=script&lang=ts&"
import style0 from "./StaffHighlight.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/buildagent/work/33e0a0de47694465/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('266a7a46')) {
      api.createRecord('266a7a46', component.options)
    } else {
      api.reload('266a7a46', component.options)
    }
    module.hot.accept("./StaffHighlight.vue?vue&type=template&id=266a7a46&", function () {
      api.rerender('266a7a46', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Preview/Highlight/StaffHighlight.vue"
export default component.exports