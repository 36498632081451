







import {Component, Vue, Prop} from "vue-property-decorator";
import SearchService, {SearchMetaData, SearchResultItem} from "../../services/SearchService";
import PageResult from "./Result/PageResult.vue";
import StaffResult from "./Result/StaffResult.vue";
import SituationResult from "./Result/SituationResult.vue";
import ServiceResult from "./Result/ServiceResult.vue";
import FormResult from "./Result/FormResult.vue";
import OfficelocationResult from "./Result/OfficelocationResult.vue";
import ResourceResult from "./Result/ResourceResult.vue";
import FormHighlight from "./Highlight/FormHighlight.vue";
import OfficelocationHighlight from "./Highlight/OfficelocationHighlight.vue";
import ServiceHighlight from "./Highlight/ServiceHighlight.vue";
import SituationHighlight from "./Highlight/SituationHighlight.vue";
import StaffHighlight from "./Highlight/StaffHighlight.vue";

@Component({
	components: {
		FormResult,
		OfficelocationResult,
		PageResult,
		ResourceResult,
		ServiceResult,
		SituationResult,
		StaffResult,

		FormHighlight,
		OfficelocationHighlight,
		ServiceHighlight,
		SituationHighlight,
		StaffHighlight,
	}
})
export default class Result extends Vue {
	@Prop() result: SearchResultItem;

	get resultComponentName() {
		return this.result._type + (this.isHighlight ? '-highlight' : '-result');
	}

	get resultClasses() {
		return [
			'c-gufi-result',
			'c-gufi-result' + (this.isHighlight ? '-highlight' : '-result'),
			'c-gufi-result-' + this.result._type
		]
	}

	get isHighlight() {
		return this.result.position === 1 && !['resource', 'page'].includes(this.result._type);
	}

	onResultClicked(data) {
		this.$emit('onResultClicked', data);
	}

	mounted() {
		this.$el.setAttribute('data-index', this.result.position.toString());
		this.$el.setAttribute('data-page', this.result.page.toString());
		this.$el.setAttribute('data-score', this.result._score.toString());
	}
}
