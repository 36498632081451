











import SearchService, {SuggestionResultItem} from "../../services/SearchService";
import {Component, Prop, Vue, Watch} from "vue-property-decorator";

const debounce = require('lodash.debounce');

@Component({
	props: {
		searchService: SearchService,
		query: String
	},
})
export default class Suggest extends Vue {
	private debouncer = null;
	searchService: SearchService;
	query: string;
	results: SuggestionResultItem[] = [];

	created() {
		this.debouncer = debounce(this.loadSuggestions, 200);
	}

	@Prop()
	private activeSuggestion: number;

	@Watch("query")
	onQueryChanged() {
		if (this.activeSuggestion === -1) {
			this.debouncer();
		}
	}

	@Watch("activeSuggestion")
	onActiveSuggestionChanged() {
		if (this.activeSuggestion > -1) {
			this.$emit('suggestionChanged', {
				title: this.results[this.activeSuggestion].title
			});
		}
	}

	listItemClass(index) {
		return {
			'c-gufi-autocomplete-item': true,
			'c-gufi-autocomplete-item-active': this.activeSuggestion === index,
		}
	}

	onSuggestionClicked(result) {
		this.$emit('suggestionClicked', {
			title: result.title
		});
	}

	async loadSuggestions() {
		const words = this.query.split(" ");
		const lastWord = words.pop();

		if (lastWord.length >= 2) {
			this.results = (await this.searchService.suggest(lastWord)).map((result) => {
				const titleWithPrefix = (words.length) ? (words.join(" ") + " " + result.title) : result.title;

				return {
					...result, ...{
						title: titleWithPrefix
					}
				}
			})
		}
	}
}
