var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-gufi-result-list" },
    [
      _vm.results.length > 0
        ? _c("search-stats", { attrs: { meta: _vm.meta } })
        : _vm._e(),
      _vm._v(" "),
      _vm.results.length === 0
        ? _c("article", { staticClass: "c-gufi-result" }, [
            _c("h1", [_vm._v(_vm._s(_vm.translate("SEARCH_NO_RESULT")))]),
            _vm._v(" "),
            _c("p", { staticClass: "c-gufi-result-snippet" }, [
              _vm._v(_vm._s(_vm.translate("SEARCH_NO_RESULT_HINT")))
            ]),
            _vm._v(" "),
            _vm.noResultFormLink.length > 0
              ? _c("p", {
                  staticClass: "c-gufi-result-snippet",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.translate("SEARCH_NO_RESULT_HINT_WITHURL", {
                        link: _vm.noResultFormLink
                      })
                    )
                  }
                })
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.results, function(result, index) {
        return _c("result", {
          key: result._id,
          attrs: { result: result, meta: _vm.meta },
          on: { onResultClicked: _vm.onResultClicked }
        })
      }),
      _vm._v(" "),
      _vm.meta.hits > _vm.results.length
        ? _c("div", { staticClass: "c-gufi-result-more" }, [
            _c(
              "button",
              {
                staticClass: "c-gufi-result-button-more",
                attrs: {
                  type: "button",
                  value: "Weitere Ergebnisse laden",
                  title: "Weitere Ergebnisse laden"
                },
                on: { click: _vm.moreResults }
              },
              [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.translate("SEARCH_MORE_RESULTS")) +
                    "\n\t\t"
                )
              ]
            )
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }