















    import {Component} from "vue-property-decorator";
    import Icon from "../../Generic/Icon.vue";
    import Button from "../../Generic/Button.vue";
    import LimitedContent from "../../Generic/LimitedContent.vue";
    import AbstractPreview from "./AbstractPreview";

    @Component({
		components: {
			Icon,
			Button,
			LimitedContent
		}
	})
	export default class DescriptionPreview extends AbstractPreview {

	}
