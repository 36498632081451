import Axios, {AxiosInstance, AxiosRequestConfig} from 'axios';
import LoggingService from "./LoggingService";
import {all as mergeAll} from 'deepmerge';

export interface SearchResultItem {
	_type: string;
	_score: number;
	id: string;
	url: string;
	title: string;
	tag?: string,
	size?: number,
	description: string;
	contentType: string;
	snippets: string[];
	page: number;
	position: number;
	data: any
}

export interface SuggestionResultItem {
	title: string;
}

export interface SearchMetaData {
	hits: number;
	pages: number;
	time: number;
}

export interface SearchResult {
	results: SearchResultItem[];
	meta: SearchMetaData;
}

export interface PreviewListResult {
	results: SearchResultItem[];
	meta: SearchMetaData;
}

export default class SearchService {
	private readonly baseEndpoint: string;
	private readonly apiKey: string;
	private contentType: string = 'all';
	private loggingService: LoggingService;
	private doRetrieve: boolean = true;

	constructor(endpoint: string, apiKey: string, loggingService: LoggingService) {
		this.baseEndpoint = endpoint;
		this.loggingService = loggingService;
		this.apiKey = apiKey;
	}

	get endpoint() {
		return this.baseEndpoint + ((this.contentType && this.contentType !== 'all') ? '_' + this.contentType : '');
	}

	public setContentType(contentType: string) {
		this.contentType = contentType;
	}

	public getContentType() {
		return this.contentType;
	}

	public disableRetrieve(){
		this.doRetrieve = false;
	}

	public suggest(query: string, limit: number = 5): Promise<SuggestionResultItem[]> {
		const axios = this.getAxiosClient({
			timeout: 3000
		});

		return axios.post(this.baseEndpoint + "/suggest", {
			"limit": limit,
			"query": query
		}).then(response => response.data).then(response => {
			return response.map(suggestion => {
				return {
					title: suggestion
				}
			});
		}).catch(error => {
			this.logError(error, 'suggest', {query, limit});

			throw error;
		});

	}

	//todo: logSearch nach GUFI-50 entfernen
	public search(query: string, limit: number, offset: number = 0, previewMode: boolean = false): Promise<SearchResult> {
		const axios = this.getAxiosClient();

		return axios.post(this.endpoint + "/search?data=1&" + ((previewMode) ? "preview=1" : ""), {
			"offset": offset,
			"limit": limit,
			"query": query
		}).then(response => response.data).then((response) => {
			const results: SearchResultItem[] = [];

			response.results.forEach((hit: any, index) => {
				const result = this.normalizeHit(hit) as SearchResultItem;
				result.page = 1 + offset / limit;
				result.position = offset + index + 1;

				results.push(result);
			});

			return {
				results,
				meta: {
					hits: response.meta.total,
					pages: Math.ceil(response.meta.total / limit),
					time: response.meta.duration / 1000,
					query: query
				},
			};
		}).catch(error => {
			this.logError(error, 'search', {query, limit, offset});

			throw error;
		});
	}

	private getAxiosClient(config?: AxiosRequestConfig): AxiosInstance {
		config = config || {};

		const defaultConfig = {
			timeout: 8000,
			headers: {}
		};
		if (this.apiKey) {
			defaultConfig.headers['x-api-key'] = this.apiKey;
		}

		return Axios.create(mergeAll([defaultConfig, config]));
	}

	public preview(query: string, limit: number): Promise<PreviewListResult> {
		return this.search(query, limit, 0, true).then((response: SearchResult) => {

			const promises: Promise<SearchResultItem>[] = response.results.map((hit: SearchResultItem) => {

				if (hit._type === 'page' && this.doRetrieve) {
					return this.retrieve(hit.data['url']).then((structuredHit: object): SearchResultItem => {
						return (structuredHit) ? this.normalizeHit(structuredHit) as SearchResultItem : hit;
					}).catch(error => {
						this.logError(error, 'preview', {query});

						return Promise.resolve(hit);
					});
				} else {
					return Promise.resolve(hit);
				}
			});

			return Promise.all(promises).then((results: SearchResultItem[]) => {
				return {
					results: results,
					meta: response.meta
				};
			}).catch(error => {
				this.logError(error, 'preview', {query});

				throw error;
			});
		});
	}

	public logClick(query: string, position: number, target: string) {
		return this.loggingService.notice({
			message: "SearchResultClicked",
			meta: {
				query: query,
				target: target,
				position: position
			}
		});
	}

	private retrieve(url: string): Promise<object> {
		const axios = this.getAxiosClient({
			timeout: 3000
		});

		return axios.post(this.baseEndpoint + "/retrieve", {
			"url": url
		}).then(response => response.data).then((response) => {
			if (response) {
				return response;
			} else {
				return null;
			}
		}).catch(error => {
			this.logError(error, 'retrieve', {url});

			throw error;
		});
	}

	private normalizeHit(hit: any): object {
		let snippets: string[] = this.getSnippets(hit.highlight);
		if (snippets.length == 0) {
			snippets.push(hit.source.description);
		}

		return {
			_type: hit.type,
			_score: hit.score,
			id: hit.type + '-' + hit.id,
			url: hit.source.url,
			title: (hit.highlight !== undefined && hit.highlight['title'] !== undefined) ? hit.highlight['title'].join('... ') : hit.source.title,
			tag: hit.source.tag,
			description: hit.source.description,
			contentType: hit.source.contentType,
			snippets: snippets,
			data: hit.source,
			size: hit.source.size
		};
	}

	private getSnippets(highlight): string[] {
		if (!highlight) {
			return [];
		}

		return Object.keys(highlight)
			.filter((key) => (key !== 'title' && key !== 'keywords'))
			.reduce((snippets: string[], highlightKey: string) => {
				return snippets.concat(highlight[highlightKey]);
			}, []);
	}

	private logError(error, method, args) {
		let meta = {
			endpoint: this.endpoint,
			url: location.href,
			...args
		};
		if (error.response !== undefined) {
			meta['status'] = error.response.status;
			meta['statusText'] = error.response.statusText;
		}

		this.loggingService.error({
			message: "Fehler beim Ausführen von " + method,
			meta: meta
		});
	}
}
