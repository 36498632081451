














    import {Component} from "vue-property-decorator";
    import Icon from "../../Generic/Icon.vue";
    import Button from "../../Generic/Button.vue";
    import LimitedContent from "../../Generic/LimitedContent.vue";
    import AbstractPreview from "./AbstractPreview";

    @Component({
		components: {
			LimitedContent,
			Icon,
			Button
		}
	})
	export default class ServicePreview extends AbstractPreview {
		get htmlContent() {
			if (this.itemData.description) {
				return this.itemData.description;
			}

			if (this.itemData.info) {
				return this.itemData.info;
			}

			return this.result.snippets.join('... ');
		}
	}
