import {Component, Prop, Vue} from "vue-property-decorator";
import {SearchResultItem} from "../../../services/SearchService";
import Icon from "../../Generic/Icon.vue";
import Translator from "../../../Translator";

@Component({
	filters: {
		limit: AbstractHighlight.limit
	},
	components: {
		Icon
	}
})
export default class AbstractHighlight extends Vue {
	@Prop() result: SearchResultItem;

	getTypeClass(contentType: string) {
		switch (contentType) {
			case "text/html":
				return "c-icon-globe";
			case "application/pdf":
				return "c-icon-file-pdf";
			/*case 2:
				return "c-icon-file-word";*/
			default:
				return "c-icon-globe";
		}
	}

	static limit(value: string, length: number = 90, limiter: string = "...") {
		return value.substr(0, length) + (value.length > length ? limiter : "");
	}

	translate(key) {
		return Translator.get(key)
	}

	logAndOpen(event) {
		if (event) {
			event.preventDefault();
		}

		const url = (this.result.data && this.result.data.url) ? this.result.data.url : this.result.url;

		this.$emit('onResultClicked', {
			url: url,
			position: this.result.position
		});
	}
}
