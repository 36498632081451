














    import {Component, Prop, Vue} from "vue-property-decorator";
    import {SearchResultItem} from "../../../services/SearchService";
    import Icon from "../../Generic/Icon.vue";
    import Button from "../../Generic/Button.vue";
    import LimitedContent from "../../Generic/LimitedContent.vue";
    import AbstractPreview from "./AbstractPreview";

    @Component({
		components: {
			LimitedContent,
			Icon,
			Button
		}
	})
	export default class SituationPreview extends AbstractPreview {

		get htmlContent() {
			if (this.itemData.description) {
				return this.itemData.description;
			}

			if (this.itemData.info) {
				return this.itemData.info;
			}

			if (this.result.snippets.length > 0) {
				return this.result.snippets.join('... ');
			}

			return false;
		}
	}
