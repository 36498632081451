





























    import {Component, Prop, Vue} from "vue-property-decorator";
    import {SearchResultItem} from "../../../services/SearchService";
    import Icon from "../../Generic/Icon.vue";
    import Button from "../../Generic/Button.vue";
    import AbstractPreview from "./AbstractPreview";

    @Component({
		components: {
			Icon,
			Button
		}
	})
	export default class StaffPreview extends AbstractPreview {

		get classes() {
			return {
				'c-gufi-preview-staff-withImage': this.itemData.previewImage
			}
		}

		get mailLink() {
			return 'mailto:' + this.itemData.mail;
		}
	}
