













    import {Component, Prop, Vue} from "vue-property-decorator";
    import {SearchResultItem} from "../../../services/SearchService";
    import Icon from "../../Generic/Icon.vue";
    import AbstractResult from "./AbstractResult";

    @Component({
		filters: {
			limit: ServiceResult.limit
		},
		components: {
			Icon
		}
	})
	export default class ServiceResult extends AbstractResult {

	}
