var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "h1",
        { staticClass: "hyphenate" },
        [
          _c("Icon", { attrs: { itemName: "officelocation" } }),
          _vm._v(
            "\n        " +
              _vm._s(_vm.translate("ITEM_OFFICELOCATION")) +
              "\n    "
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "c-gufi-row" }, [
        _c("div", { staticClass: "c-gufi-col" }, [
          _c("h2", [_vm._v(_vm._s(_vm.result.data.title))]),
          _vm._v(" "),
          _c("ul", [
            _vm.result.data.subTitle
              ? _c("li", [_vm._v(_vm._s(_vm.result.data.subTitle))])
              : _vm._e(),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                _vm._s(_vm.result.data.street) +
                  " " +
                  _vm._s(_vm.result.data.houseNumber)
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                _vm._s(_vm.result.data.zipcode) +
                  " " +
                  _vm._s(_vm.result.data.town)
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _vm.result.data.previewImage
          ? _c("div", { staticClass: "c-gufi-col" }, [
              _c("figure", { staticClass: "c-gufi-figure" }, [
                _c("img", {
                  staticClass: "c-gufi-img",
                  attrs: { src: _vm.result.data.previewImage }
                })
              ])
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "Button",
        {
          attrs: {
            href: _vm.result.data.url,
            onClick: _vm.logAndOpen,
            additionalClasses: ["c-gufi-more-information"]
          }
        },
        [_vm._v(_vm._s(_vm.translate("SEARCH_MORE_INFORMATION")))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }