







import {Component, Prop, Vue} from "vue-property-decorator";
import Translator from "../../Translator";

@Component({})
export default class Error extends Vue {
	@Prop() message: string = "";

	translate(key) {
		return Translator.get(key)
	}
}
