var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("fieldset", { staticClass: "c-gufi-toolbar" }, [
    _c("legend", [_vm._v(_vm._s(_vm.translate("CONTENTTYPE")))]),
    _vm._v(" "),
    _c("input", {
      attrs: {
        type: "radio",
        id: "sm-all",
        name: "gufiToolbar",
        checked: "checked",
        value: "all"
      },
      on: { change: _vm.onContentTypeChanged }
    }),
    _vm._v(" "),
    _c("label", { attrs: { for: "sm-all" } }, [
      _vm._v(_vm._s(_vm.translate("CONTENTTYPE_ALL")))
    ]),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.contentType,
          expression: "contentType"
        }
      ],
      attrs: {
        type: "radio",
        id: "sm-web",
        name: "gufiToolbar",
        value: "pages"
      },
      domProps: { checked: _vm._q(_vm.contentType, "pages") },
      on: {
        change: [
          function($event) {
            _vm.contentType = "pages"
          },
          _vm.onContentTypeChanged
        ]
      }
    }),
    _vm._v(" "),
    _c("label", { attrs: { for: "sm-web" } }, [
      _vm._v(_vm._s(_vm.translate("CONTENTTYPE_PAGES")))
    ]),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.contentType,
          expression: "contentType"
        }
      ],
      attrs: {
        type: "radio",
        id: "sm-doc",
        name: "gufiToolbar",
        value: "resources"
      },
      domProps: { checked: _vm._q(_vm.contentType, "resources") },
      on: {
        change: [
          function($event) {
            _vm.contentType = "resources"
          },
          _vm.onContentTypeChanged
        ]
      }
    }),
    _vm._v(" "),
    _c("label", { attrs: { for: "sm-doc" } }, [
      _vm._v(_vm._s(_vm.translate("CONTENTTYPE_RESOURCES")))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }