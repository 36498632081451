var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", [
      _c("a", {
        staticClass: "c-gufi-result-headline",
        attrs: { href: _vm.result.url },
        domProps: { innerHTML: _vm._s(_vm.result.title) },
        on: {
          click: function($event) {
            return _vm.logAndOpen($event, _vm.result.url)
          }
        }
      }),
      _vm._v(" "),
      _vm.result.size
        ? _c("span", { staticClass: "c-gufi-result-size" }, [
            _vm._v("[" + _vm._s(_vm.bytesToSize(_vm.result.size)) + "]")
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _vm.result.tag
      ? _c("p", { staticClass: "c-gufi-result-tag" }, [
          _vm._v(_vm._s(_vm.result.tag))
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.result.snippets
      ? _c("p", {
          staticClass: "c-gufi-result-snippet",
          domProps: {
            innerHTML: _vm._s(_vm.result.snippets.slice(0, 3).join("... "))
          }
        })
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "c-gufi-result-extra-url" },
      [
        !_vm.result.tag
          ? _c("Icon", { attrs: { contentType: _vm.result.contentType } })
          : _vm._e(),
        _vm._v(" "),
        _vm.result.tag
          ? _c("span", {
              staticClass: "c-icon c-icon-arrow",
              attrs: { title: _vm.result.tag }
            })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "c-gufi-result-url",
            attrs: { href: _vm.result.url },
            on: {
              click: function($event) {
                return _vm.logAndOpen($event, _vm.result.url)
              }
            }
          },
          [_vm._v(_vm._s(_vm._f("limit")(_vm.result.url)))]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }