var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.result
    ? _c(
        "div",
        [
          _c(
            "h1",
            { staticClass: "hyphenate" },
            [
              _c("Icon", { attrs: { contentType: _vm.result.contentType } }),
              _vm._v(" "),
              _c("span", { domProps: { innerHTML: _vm._s(_vm.result.title) } })
            ],
            1
          ),
          _vm._v(" "),
          _vm.result.snippets
            ? _c("LimitedContent", { attrs: { "max-height": "150" } }, [
                _c("p", {
                  domProps: {
                    innerHTML: _vm._s(_vm.result.snippets.join("... "))
                  }
                })
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "Button",
            {
              attrs: {
                href: _vm.result.url,
                onClick: _vm.logAndOpen,
                additionalClasses: ["c-gufi-more-information"]
              }
            },
            [_vm._v(_vm._s(_vm.translate("SEARCH_MORE_INFORMATION")))]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }