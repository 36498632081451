























import 'url-search-params-polyfill';
import {Component, Prop, Vue} from "vue-property-decorator";
import SearchForm from "./components/List/SearchForm.vue";
import SearchService, {SearchMetaData, SearchResultItem} from "./services/SearchService";
import ResultList from '@/components/List/ResultList.vue';
import Error from '@/components/List/Error.vue';
import LoggingService from "./services/LoggingService";
import {Constants} from "./Constants";
import Translator from "./Translator";

@Component({
	components: {
		ResultList,
		SearchForm,
		Error
	}
})
export default class GufiList extends Vue {
	@Prop()
	private container: string;
	@Prop()
	private config;

	showResults: boolean = false;
	showError: boolean = false;

	results: SearchResultItem[] = [];
	meta: SearchMetaData = {hits: 0, pages: 0, time: 0};

	query: string = "";
	limit: number = 20;
	offset: number = 0;
	contentType: string = "all";

	errorMessage: string = "";

	private loggingService: LoggingService = new LoggingService(Constants.logging_endpoint, Constants.logging_apiKey, Constants.logging_softwareName, this.container);
	private searchService: SearchService = new SearchService(Constants.search_endpoint + this.container, this.config.key, this.loggingService);

	created() {
		const urlParams = new URLSearchParams(window.location.search);

		if (urlParams.has("limit")) {
			this.limit = parseInt(urlParams.get('limit'));
		}

		if (urlParams.has("offset")) {
			this.offset = parseInt(urlParams.get('offset'));
		}

		if (urlParams.has("contentType")) {
			this.contentType = urlParams.get('contentType');
			this.searchService.setContentType(this.contentType);
		}

		if (urlParams.has("query") && urlParams.get('query') !== "") {
			this.query = urlParams.get('query');

			this.onDoSearch();
		}

		if (this.config.language) {
			Translator.setLocale(this.config.language)
		}
	}

	onDoSearch() {
		if (this.query.trim().length === 0) {
			return;
		}

		const urlParams = new URLSearchParams(window.location.search);
		urlParams.set("query", this.query);
		urlParams.set("limit", this.limit.toString());
		urlParams.set("offset", this.offset.toString());
		urlParams.set("contentType", this.searchService.getContentType());

		history.pushState({}, document.title, location.protocol + '//' + location.host + location.pathname + '?' + urlParams.toString());

		this.searchService.search(this.query, this.limit, this.offset).then(result => {
			const results = result.results;

			this.meta = result.meta;

			if (this.offset > 0) {
				this.results = this.results.concat(results);
			} else {
				this.results = results;
			}

			this.showResults = true;
			this.showError = false;
		}).catch(result => {
			this.showResults = false;
			this.showError = true;
			console.warn(result);
		});
	}

	onResultClicked(data) {
		this.searchService.logClick(this.query, data.position, data.url).then(() => {
			location.href = data.url;
		});
	}

	loadMoreResults() {
		this.offset = this.offset + 20;
		this.onDoSearch();
	}

	onResetForm() {
		this.showResults = false;

		this.limit = 20;
		this.offset = 0;
		this.query = "";
	}

	onQueryChanged(query) {
		this.limit = 20;
		this.offset = 0;

		this.query = query;
	}

	onContentTypeChanged(contentType) {
		this.searchService.setContentType(contentType);

		if (this.query !== "") {
			this.onDoSearch();
		}
	}
}
