import { render, staticRenderFns } from "./Suggest.vue?vue&type=template&id=584b59a3&"
import script from "./Suggest.vue?vue&type=script&lang=ts&"
export * from "./Suggest.vue?vue&type=script&lang=ts&"
import style0 from "./Suggest.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/buildagent/work/33e0a0de47694465/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('584b59a3')) {
      api.createRecord('584b59a3', component.options)
    } else {
      api.reload('584b59a3', component.options)
    }
    module.hot.accept("./Suggest.vue?vue&type=template&id=584b59a3&", function () {
      api.rerender('584b59a3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/List/Suggest.vue"
export default component.exports