var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-gufi-result-stats" }, [
    _vm._v(
      _vm._s(_vm.meta.hits.toLocaleString()) +
        " " +
        _vm._s(_vm.translate("SEARCH_RESULTS_WITH_TIME")) +
        " " +
        _vm._s(_vm.meta.time.toLocaleString()) +
        " s"
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }