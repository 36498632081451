
























import {Component, Prop, Vue} from "vue-property-decorator";
import Suggest from './Suggest.vue';
import SearchBar from "../Generic/SearchBar.vue";
import ContentTypeBar from "./ContentTypeBar.vue";
import SearchService from "../../services/SearchService";

@Component({
	components: {ContentTypeBar, SearchBar, Suggest},
})
export default class SearchForm extends Vue {
	@Prop()
	toolbarEnabled: boolean = true;
	@Prop()
	searchService: SearchService;
	@Prop()
	query: string;
	@Prop()
	contentType: string;

	showSuggestions: boolean = true;
	activeSuggestion: number = -1;

	get queryHasText() {
		return this.query !== "";
	}

	onDoSearch() {
		this.showSuggestions = false;

		this.$emit("onDoSearch");
	}

	onResetForm() {
		this.showSuggestions = true;

		this.$emit("onResetForm");
	}

	submitForm(e: Event) {
		e.preventDefault();

		this.onDoSearch();
	}

	onSuggestionClicked(suggestion) {
		this.$emit("onQueryChanged", suggestion.title);

		this.onDoSearch();
	}

	onSuggestionChanged(suggestion) {
		this.$emit("onQueryChanged", suggestion.title);
	}

	onQueryChanged(query) {
		this.showSuggestions = true;

		this.$emit("onQueryChanged", query);
	}

	onContentTypeChanged(contentType) {
		this.$emit("onContentTypeChanged", contentType);
	}

	changeActiveSuggestionDown() {
		this.activeSuggestion += 1;
	}

	changeActiveSuggestionUp() {
		this.activeSuggestion -= 1;
	}

	onResetActiveSuggestion() {
		this.activeSuggestion = -1;
	}
}
