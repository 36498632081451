import {Component, Prop, Vue} from "vue-property-decorator";
import SearchService, {SearchMetaData, SearchResultItem} from "../../../services/SearchService";
import Icon from "../../Generic/Icon.vue";
import Translator from "../../../Translator";

@Component({})
export default class AbstractPreview extends Vue {
	@Prop({default: null}) result: SearchResultItem;
	@Prop({default: null}) itemData: any;
	@Prop() searchService: SearchService;
	@Prop() meta: SearchMetaData;

	translate(key) {
		return Translator.get(key)
	}

	logAndOpen(event) {
		event.preventDefault();

		const url = (this.itemData && this.itemData.url) ? this.itemData.url : this.result.url;

		this.$emit('onResultClicked', {
			url: url,
			position: this.result.position
		});
	}
}
