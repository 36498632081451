















    import {Component, Vue, Prop} from "vue-property-decorator";
    import {SearchResultItem} from "../../../services/SearchService";
    import Icon from "../../Generic/Icon.vue";
    import Button from "../../Generic/Button.vue";
    import LimitedContent from "../../Generic/LimitedContent.vue";
    import AbstractPreview from "./AbstractPreview";

    @Component({
		components: {
			Icon,
			Button,
			LimitedContent
		}
	})
	export default class ResourcePreview extends AbstractPreview {
	}
