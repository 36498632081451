

















import {Component, Prop, Vue} from "vue-property-decorator";
import Translator from "../../Translator";

@Component({})
export default class SearchBar extends Vue {
	@Prop() query: string;

	translate(key) {
		return Translator.get(key)
	}

	get queryHasText() {
		return this.query !== "";
	}

	doSearch(event) {
		if (event) event.preventDefault();

		this.$emit("onDoSearch");
	}

	resetForm() {
		this.$emit("onResetForm");
	}

	onMouseOver() {
		this.$emit("onMouseOver");
	}

	onKeyUp(e: KeyboardEvent) {
		if (e.code === 'ArrowDown') {
			this.$emit("changeActiveSuggestionDown");
		} else if (e.code === 'ArrowUp') {
			this.$emit("changeActiveSuggestionUp");
		} else if (e.code === 'Space') {
			this.$emit("resetActiveSuggestion");
		} else if (e.code === 'Enter') {
			this.doSearch(e);
		} else if (e.code === 'Escape') {
			this.resetForm();
		}
	}
}
