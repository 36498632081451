var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      class: _vm.previewResultClasses,
      on: { mouseover: _vm.onActiveResultChanged }
    },
    [
      _vm.isHighlight
        ? _c(
            "div",
            { staticClass: "highlight" },
            [
              _c(_vm.highlightComponentName, {
                tag: "component",
                attrs: { result: _vm.result }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("Icon", {
            attrs: {
              itemName: _vm.result._type,
              contentType: _vm.result.contentType
            }
          }),
          _vm._v(" "),
          _c("a", {
            attrs: { href: _vm.result.url },
            domProps: { innerHTML: _vm._s(_vm.result.title) },
            on: { click: _vm.logAndOpen, focus: _vm.onActiveResultChanged }
          }),
          _vm._v(" "),
          _vm.result.tag
            ? _c("span", { staticClass: "c-gufi-preview-tag" }, [
                _vm._v(_vm._s(_vm.result.tag))
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.result.tag
            ? _c("span", {
                staticClass: "c-icon c-icon-arrow",
                attrs: { title: _vm.result.tag }
              })
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }