











    import {Component, Prop, Vue} from "vue-property-decorator";
	import SearchService, {SearchMetaData, SearchResultItem} from "../../services/SearchService";
	import Preview from "./Preview.vue";

	@Component({
		components: {
			Preview
		}
	})
	export default class PreviewsWrapper extends Vue {
		@Prop({default: null}) activeResult: SearchResultItem;
		@Prop({default: null}) results: SearchResultItem[];

		onResultClicked(data) {
			this.$emit('onResultClicked', data);
		}
	}
