var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "h1",
      { staticClass: "hyphenate" },
      [
        _c("Icon", { attrs: { itemName: "staff" } }),
        _vm._v("\n        " + _vm._s(_vm.translate("ITEM_STAFF")))
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "c-gufi-row" }, [
      _c(
        "div",
        { staticClass: "c-gufi-col" },
        [
          _c("h2", [
            _vm._v(
              _vm._s(_vm.result.data.salutation) +
                " " +
                _vm._s(_vm.result.data.firstname) +
                " " +
                _vm._s(_vm.result.data.surname)
            )
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v(
              "\n                " +
                _vm._s(_vm.result.data.role) +
                "\n            "
            )
          ]),
          _vm._v(" "),
          _c("ul", [
            _c("li", [
              _vm._v(
                _vm._s(_vm.result.data.phoneAreacode) +
                  " " +
                  _vm._s(_vm.result.data.phoneNumber)
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _c("a", { attrs: { href: _vm.mailLink } }, [
                _vm._v(_vm._s(_vm.result.data.mail))
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "Button",
            {
              attrs: {
                href: _vm.result.data.url,
                onClick: _vm.logAndOpen,
                additionalClasses: ["c-gufi-more-information"]
              }
            },
            [_vm._v(_vm._s(_vm.translate("SEARCH_MORE_INFORMATION")))]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "c-gufi-col" }, [
        _c("figure", { staticClass: "c-gufi-figure" }, [
          _c("img", {
            staticClass: "c-gufi-img",
            attrs: { src: _vm.result.data.previewImage }
          })
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }