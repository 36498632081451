




import {Component, Prop, Vue} from "vue-property-decorator";

@Component({})
export default class Overlay extends Vue {
	@Prop() isActive: boolean;

	get classes() {
		return {
			'c-gufi-overlay': true,
			'c-gufi-overlay-active': this.isActive,
		}
	}

	onOverlayClicked() {
		this.$emit('onOverlayClicked');
	}
}
