








import {Component, Prop, Vue} from "vue-property-decorator";
import PreviewResult from "./PreviewResult.vue";
import SearchService, {SearchMetaData, SearchResultItem, SuggestionResultItem} from "../../services/SearchService";
import SearchStats from "../Generic/SearchStats.vue";
import Button from "../Generic/Button.vue";
import Translator from "../../Translator";

@Component({
	components: {PreviewResult, SearchStats, Button},
	props: {}
})
export default class PreviewSuggestionList extends Vue {
	@Prop() suggestions: SuggestionResultItem[];

	onSuggestionClicked(query) {
		this.$emit("onQueryChanged", query);
	}
}
