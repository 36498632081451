var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.results.length > 0
    ? _c(
        "ul",
        { staticClass: "autocomplete" },
        _vm._l(_vm.results, function(result, index) {
          return _c(
            "li",
            {
              class: _vm.listItemClass(index),
              on: {
                click: function($event) {
                  return _vm.onSuggestionClicked(result)
                }
              }
            },
            [_vm._v("\n            " + _vm._s(result.title) + "\n        ")]
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }