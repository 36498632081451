import {Component, Prop, Vue} from "vue-property-decorator";
import {SearchResultItem} from "../../../services/SearchService";
import Icon from "../../Generic/Icon.vue";

@Component({
	filters: {
		limit: AbstractResult.limit
	},
	components: {
		Icon
	}
})
export default class AbstractResult extends Vue {
	@Prop() result: SearchResultItem;

	getTypeClass(contentType: string) {
		switch (contentType) {
			case "text/html":
				return "c-icon-globe";
			case "application/pdf":
				return "c-icon-file-pdf";
			/*case 2:
				return "c-icon-file-word";*/
			default:
				return "c-icon-globe";
		}
	}

	static limit(value: string, length: number = 90, limiter: string = "...") {
		return value.substr(0, length) + (value.length > length ? limiter : "");
	}

	logAndOpen(event, url) {
		event.preventDefault();

		this.$emit('onResultClicked', {
			url: url,
			position: this.result.position
		});
	}
}
