






    import {Component, Prop, Vue} from "vue-property-decorator";
    import SearchService, {SearchMetaData, SearchResultItem} from "../../services/SearchService";
    import SnippetsPreview from "./Previews/SnippetsPreview.vue";
    import DescriptionPreview from "./Previews/DescriptionPreview.vue";
    import SituationPreview from "./Previews/SituationPreview.vue";
    import StaffPreview from "./Previews/StaffPreview.vue";
    import FormPreview from "./Previews/FormPreview.vue";
    import OfficelocationPreview from "./Previews/OfficelocationPreview.vue";
    import ServicePreview from "./Previews/ServicePreview.vue";
    import ResourcePreview from "./Previews/ResourcePreview.vue";

    @Component({
		components: {
			DescriptionPreview,
			FormPreview,
			OfficelocationPreview,
			ResourcePreview,
			ServicePreview,
			SituationPreview,
			SnippetsPreview,
			StaffPreview
		}
	})
	export default class Preview extends Vue {
		@Prop({default: null}) result: SearchResultItem;
		@Prop({default: false}) isActive: boolean;

		private previewType: String = "snippets";

		get previewClasses() {
			return ["c-gufi-preview", "c-gufi-preview-" + this.previewType, (this.isActive) ? 'c-gufi-preview-active' : ''];
		}

		get previewComponentName() {
			return this.previewType + "-preview";
		}

		onResultClicked(data) {
			this.$emit('onResultClicked', data);
		}

		mounted() {
			if (this.result._type !== 'page') {
				this.previewType = this.result._type;
			} else if (this.result.description !== "") {
				this.previewType = "description";
			} else {
				this.previewType = "snippets";
			}
		}
	}
