export default class Translator {
	private static locale: string = 'de';
	private static translations: object = {
		de: {
			'SEARCH_WHAT': 'Wonach suchen Sie?',
			'SEARCH_DELETE': 'Sucheingabe löschen',
			'SEARCH_START': 'Suchen',
			'SEARCH_ENTER_TERM': 'Suchbegriff eingeben',
			'SEARCH_RESULTS_WITH_TIME': 'Ergebnisse in',
			'SEARCH_NO_RESULT': 'Nichts gefunden?',
			'SEARCH_NO_RESULT_HINT': 'Möglicherweise finden Sie die gesuchte Seite mit einem anderen Suchbegriff.',
			'SEARCH_NO_RESULT_HINT_WITHURL': 'Teilen Sie uns mit welche Inhalte Sie auf unserer Website gesucht und nicht gefunden haben. Durch Ihre Hinweise, können wir unseren Internetauftritt noch besser machen. <br/> <span class="cProject_linkButton"><a href=":link" target="_blank">Ich-find-nix-Formular</a></span>',
			'SEARCH_ALL_RESULTS': 'Alle Suchergebnisse anzeigen',
			'SEARCH_MORE_RESULTS': 'Weitere Ergebnisse',
			'SEARCH_MORE_INFORMATION': 'Weitere Informationen',
			'CONTENTTYPE': 'Suchmodus',
			'CONTENTTYPE_ALL': 'Alles',
			'CONTENTTYPE_PAGES': 'Website',
			'CONTENTTYPE_RESOURCES': 'Dokumente',
			'ERROR_GENERIC': 'Leider können wir Ihre Suchanfrage derzeit nicht bearbeiten. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.',
			'OPEN': 'Für Sie geöffnet',
			'DAY_MON': 'Montag',
			'DAY_TUE': 'Dienstag',
			'DAY_WED': 'Mittwoch',
			'DAY_THU': 'Donnerstag',
			'DAY_FRI': 'Freitag',
			'DAY_SAT': 'Samstag',
			'DAY_SUN': 'Sonntag',
			'ITEM_OFFICELOCATION': 'Verwaltungssitz/Behörde',
			'ITEM_STAFF': 'Ihr Ansprechpartner',
			'JUMP_TO_PREVIEW': 'Zur Vorschau springen',
		},
		en: {
			'SEARCH_WHAT': 'Search for topic',
			'SEARCH_DELETE': 'Delete search term',
			'SEARCH_START': 'Search',
			'SEARCH_ENTER_TERM': 'Enter search term',
			'SEARCH_RESULTS_WITH_TIME': 'results in',
			'SEARCH_NO_RESULT': 'No result',
			'SEARCH_NO_RESULT_HINT': 'No results found',
			'SEARCH_ALL_RESULTS': 'Show all results',
			'SEARCH_MORE_RESULTS': 'Further results',
			'SEARCH_MORE_INFORMATION': 'Further information',
			'CONTENTTYPE': 'Search mode',
			'CONTENTTYPE_ALL': 'All',
			'CONTENTTYPE_PAGES': 'Website',
			'CONTENTTYPE_RESOURCES': 'Documents',
			'ERROR_GENERIC': 'Unfortunately we can\'t process your search query right now. Please try again later.',
			'OPEN': 'Opening hours',
			'DAY_MON': 'Monday',
			'DAY_TUE': 'Tuesday',
			'DAY_WED': 'Wednesday',
			'DAY_THU': 'Thursday',
			'DAY_FRI': 'Friday',
			'DAY_SAT': 'Saturday',
			'DAY_SUN': 'Sunday',
			'ITEM_OFFICELOCATION': 'Public Authority',
			'ITEM_STAFF': 'Your contact person',
			'JUMP_TO_PREVIEW': 'Jump to preview',
		},
		fr: {
			'SEARCH_WHAT': 'Que cherchez-vous?',
			'SEARCH_DELETE': 'Effacer l’historique de recherche',
			'SEARCH_START': 'Rechercher',
			'SEARCH_ENTER_TERM': 'Entrer un mot-clef',
			'SEARCH_RESULTS_WITH_TIME': 'Résultats',
			'SEARCH_NO_RESULT': 'Aucun résultat trouvé',
			'SEARCH_NO_RESULT_HINT': 'Votre recherche reste sans réponse? Choisissez un autre mot-clef.',
			'SEARCH_ALL_RESULTS': 'Afficher tous les résultats de la recherche',
			'SEARCH_MORE_RESULTS': 'Résultats supplémentaires',
			'SEARCH_MORE_INFORMATION': 'Informations supplémentaires',
			'CONTENTTYPE': 'Mode de recherche',
			'CONTENTTYPE_ALL': 'Tout',
			'CONTENTTYPE_PAGES': 'Site web',
			'CONTENTTYPE_RESOURCES': 'Documents',
			'ERROR_GENERIC': 'Nous ne pouvons malheureusement pas donner suite actuellement à votre recherche. Réitérez votre essai ultérieurement.',
			'OPEN': 'Ouvert pour vous',
			'DAY_MON': 'Lundi',
			'DAY_TUE': 'Mardi',
			'DAY_WED': 'Mercredi',
			'DAY_THU': 'Jeudi',
			'DAY_FRI': 'Vendredi',
			'DAY_SAT': 'Samedi',
			'DAY_SUN': 'Dimanche',
			'ITEM_OFFICELOCATION': 'Siège administratif/Organisme public',
			'ITEM_STAFF': 'Votre interlocuteur',
			'JUMP_TO_PREVIEW': 'Parcourir les aperçus',
		}
	};

	public static setLocale(locale) {
		this.locale = locale;
	}

	public static get(key, parameters = {}) {
		if (!this.translations[this.locale] || !this.translations[this.locale][key]) {
			return key;
		}

		let translation = this.translations[this.locale][key];

		Object.keys(parameters).forEach((parameterName) => {
			translation = translation.replace(':' + parameterName, parameters[parameterName]);
		});

		return translation;
	}
}
