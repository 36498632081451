





import {Component, Prop, Vue} from "vue-property-decorator";

@Component({})
export default class Icon extends Vue {
	@Prop() itemName: String;
	@Prop() contentType: String;

	get itemIconClass() {
		//todo: In Konfiguration auslagern
		switch (this.itemName) {
			case "form":
				return "c-icon-file-pdf";
			case "officelocation":
				return "c-icon-placeholder";
			case "service":
				return "c-icon-stamp";
			case "situation":
				return "c-icon-stamp";
			case "staff":
				return "c-icon-user";
		}

		if (this.contentType === "application/pdf") {
			return "c-icon-file-pdf"
		} else {
			return "c-icon-globe"
		}
	}

	get itemTitle() {
		//todo: In Konfiguration auslagern
		switch (this.itemName) {
			case "form":
				return "Formular";
			case "officelocation":
				return "Verwaltungssitz";
			case "service":
				return "Dienstleistung";
			case "situation":
				return "Lebenslage";
			case "staff":
				return "Mitarbeiter";
			default:
				return false;
		}
	}
}
