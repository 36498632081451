var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      class: _vm.addAdditionalClasses({
        cProject_linkButton: true,
        cProject_linkButton_grey: _vm.type === "invert"
      })
    },
    [
      _c(
        "a",
        {
          attrs: { href: _vm.href },
          on: {
            click: function($event) {
              _vm.onClick ? _vm.onClick($event) : null
            }
          }
        },
        [_vm._t("default")],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }