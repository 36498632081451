var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fieldset",
    { staticClass: "c-gufi-searchbar", on: { mouseover: _vm.onMouseOver } },
    [
      _c("input", {
        ref: "searchInput",
        staticClass: "c-gufi-search-input",
        attrs: {
          "aria-label": "Suchbegriff eingeben",
          type: "search",
          placeholder: _vm.translate("SEARCH_WHAT"),
          autocomplete: "off",
          autocorrect: "off",
          autocapitalize: "off",
          spellcheck: "false"
        },
        domProps: { value: _vm.query },
        on: {
          input: function($event) {
            return _vm.$emit("onQueryChanged", $event.target.value)
          },
          keyup: _vm.onKeyUp
        }
      }),
      _vm._v(" "),
      _vm.queryHasText
        ? _c(
            "button",
            {
              staticClass: "c-gufi-search-clear",
              attrs: { type: "button", title: _vm.translate("SEARCH_DELETE") },
              on: { click: _vm.resetForm }
            },
            [
              _c("span", { staticClass: "c-icon c-icon-cancel" }, [
                _c("span", { staticClass: "c-gufi-search-button-label" }, [
                  _vm._v(_vm._s(_vm.translate("SEARCH_DELETE")))
                ])
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "c-gufi-search-button",
          attrs: { type: "submit", title: _vm.translate("SEARCH_START") },
          on: { click: _vm.doSearch }
        },
        [
          _c("span", { staticClass: "c-icon c-icon-search" }, [
            _c("span", { staticClass: "c-gufi-search-button-label" }, [
              _vm._v(_vm._s(_vm.translate("SEARCH_START")))
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }