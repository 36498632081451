





import {Component, Prop, Vue} from "vue-property-decorator";
import {SearchMetaData} from "../../services/SearchService";
import Translator from "../../Translator";

@Component({})
export default class SearchStats extends Vue {
	@Prop()
	private meta: SearchMetaData;

	translate(key) {
		return Translator.get(key)
	}
}
