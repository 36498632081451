







import {Component, Prop, Vue} from "vue-property-decorator";

@Component({})
export default class LimitedContent extends Vue {
	@Prop({default: 150}) maxHeight: number;

	private clientHeight = 0;

	get classes() {
		return {
			'c-gufi-limited-content': true,
			'c-gufi-limited-content-limited': this.clientHeight >= this.maxHeight
		}
	}

	mounted() {
		this.clientHeight = this.$el.clientHeight;
	}

	updated() {
		if (this.clientHeight != this.$el.clientHeight) {
			this.clientHeight = this.$el.clientHeight;
		}
	}
}
