


















import {Component, Prop, Vue} from "vue-property-decorator";
import PreviewResult from "./PreviewResult.vue";
import SearchService, {SearchMetaData, SearchResultItem} from "../../services/SearchService";
import SearchStats from "../Generic/SearchStats.vue";
import Button from "../Generic/Button.vue";
import Translator from "../../Translator";

@Component({
	components: {PreviewResult, SearchStats, Button},
	props: {
		limit: Number,
		results: {
			type: Array,
			default: function () {
				return [];
			}
		}
	}
})
export default class PreviewResultList extends Vue {
	@Prop() listLink;
	@Prop() meta: SearchMetaData;
	@Prop({default: null}) activeResult: SearchResultItem;

	translate(key) {
		return Translator.get(key)
	}

	moreResults() {
		this.$emit('loadMoreResults');
	}

	onActiveResultChanged(result: SearchResultItem) {
		this.$emit('onActiveResultChanged', result)
	}

	onResultClicked(data) {
		this.$emit('onResultClicked', data);
	}
}
