var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "c-gufi-search" },
    [
      _c("search-form", {
        attrs: {
          toolbarEnabled: true,
          searchService: _vm.searchService,
          query: _vm.query,
          contentType: _vm.contentType
        },
        on: {
          onDoSearch: _vm.onDoSearch,
          onResetForm: _vm.onResetForm,
          onQueryChanged: _vm.onQueryChanged,
          onContentTypeChanged: _vm.onContentTypeChanged
        }
      }),
      _vm._v(" "),
      _vm.showResults
        ? _c("result-list", {
            attrs: {
              results: _vm.results,
              meta: _vm.meta,
              limit: _vm.limit,
              noResultFormLink:
                _vm.config.urls && _vm.config.urls.noResultFormLink
                  ? _vm.config.urls.noResultFormLink
                  : ""
            },
            on: {
              loadMoreResults: _vm.loadMoreResults,
              onResultClicked: _vm.onResultClicked
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showError
        ? _c("error", { attrs: { message: _vm.errorMessage } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }