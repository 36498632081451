






















import {Component, Prop, Vue} from "vue-property-decorator";
import Result from "./Result.vue";
import SearchStats from "../Generic/SearchStats.vue";
import SearchService from "../../services/SearchService";
import Translator from "../../Translator";

@Component({
	components: {Result, SearchStats},
	props: {
		limit: Number,
		results: {
			type: Array,
			default: function () {
				return [];
			}
		},
		meta: {
			type: Object,
			default: function () {
				return {};
			}
		}
	}
})
export default class ResultList extends Vue {
	@Prop()
	private noResultFormLink: string;

	translate(key, parameters = {}) {
		return Translator.get(key, parameters)
	}

	moreResults() {
		this.$emit('loadMoreResults');
	}

	onResultClicked(data) {
		this.$emit('onResultClicked', data);
	}
}
