














import {Component, Prop, Vue} from "vue-property-decorator";
import Translator from "../../Translator";

@Component({})
export default class ContentTypeBar extends Vue {
	@Prop() contentType: string;

	translate(key) {
		return Translator.get(key)
	}

	onContentTypeChanged(event) {
		this.$emit('onContentTypeChanged', event.target.value);
	}
}
