






import {Component, Prop, Vue} from "vue-property-decorator";
import PreviewResult from "./PreviewResult.vue";
import {SuggestionResultItem} from "../../services/SearchService";
import SearchStats from "../Generic/SearchStats.vue";
import Button from "../Generic/Button.vue";

@Component({
	components: {PreviewResult, SearchStats, Button},
	props: {}
})
export default class SearchBarSuggestion extends Vue {
	@Prop() suggestions: SuggestionResultItem[];

	onSuggestionClicked(query){
		this.$emit("onQueryChanged", query);
	}
}
