









import {Component, Prop, Vue} from "vue-property-decorator";

@Component({})
export default class Button extends Vue {
	@Prop() type: String;
	@Prop() href: String;
	@Prop() onClick: Function;
	@Prop() additionalClasses: Array<string>;

	addAdditionalClasses(object) {
		if (this.additionalClasses) {
			this.additionalClasses.forEach(function (className) {
				object[className] = true;
			})
		}
		return object
	}
}
