var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-gufi-error" }, [
    _vm.message.length ? _c("span", [_vm._v(_vm._s(_vm.message))]) : _vm._e(),
    _vm._v(" "),
    !_vm.message.length
      ? _c("span", [_vm._v(_vm._s(_vm.translate("ERROR_GENERIC")))])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }