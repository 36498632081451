var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "section",
        {
          staticClass: "c-gufi-search c-gufi-search-preview",
          class: { "c-gufi-search-hastext": _vm.queryHasText },
          on: { keyup: _vm.onKeyUp }
        },
        [
          _c(
            "form",
            {
              staticClass: "c-gufi-searchbar-wrapper",
              on: { submit: _vm.submitForm }
            },
            [
              _c("search-bar", {
                attrs: { query: _vm.query },
                on: {
                  onQueryChanged: _vm.onQueryChanged,
                  onDoSearch: _vm.showResultList,
                  onResetForm: _vm.onCancelSearch,
                  onMouseOver: _vm.onMouseOver
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              class: _vm.wrapperClasses,
              on: {
                focusout: _vm.onFocusOut,
                focusin: _vm.onFocusIn,
                mouseleave: _vm.onMouseLeave
              }
            },
            [
              _c("preview-suggestion-list", {
                attrs: { suggestions: _vm.suggestions },
                on: { onQueryChanged: _vm.onQueryChanged }
              }),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("preview-result-list", {
                    attrs: {
                      results: _vm.results,
                      activeResult: _vm.activeResult,
                      listLink: _vm.listLink,
                      meta: _vm.meta
                    },
                    on: {
                      onResultClicked: _vm.onResultClicked,
                      onActiveResultChanged: _vm.onActiveResultChanged
                    }
                  }),
                  _vm._v(" "),
                  _c("previews-wrapper", {
                    attrs: {
                      results: _vm.results,
                      activeResult: _vm.activeResult
                    },
                    on: { onResultClicked: _vm.onResultClicked }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("overlay", {
        attrs: { isActive: _vm.showPreview && _vm.results.length > 0 },
        on: { overlayClicked: _vm.onOverlayClicked }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }