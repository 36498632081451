var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.suggestions.length
    ? _c("div", { staticClass: "c-gufi-preview-suggestion-list" }, [
        _c(
          "ul",
          _vm._l(_vm.suggestions, function(suggestion) {
            return _c("li", [
              _c(
                "button",
                {
                  attrs: { title: suggestion.title },
                  on: {
                    click: function($event) {
                      return _vm.onSuggestionClicked(suggestion.title)
                    }
                  }
                },
                [_vm._v(_vm._s(suggestion.title))]
              )
            ])
          }),
          0
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }