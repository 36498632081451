var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.suggestions.length
    ? _c("div", { staticClass: "c-gufi-searchbar-suggestion" }, [
        _c("span", [_vm._v(_vm._s(_vm.suggestions[0].title))])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }