




























    import {Component, Prop, Vue} from "vue-property-decorator";
    import Icon from "../../Generic/Icon.vue";
    import AbstractHighlight from "./AbstractHighlight";
    import Button from "../../Generic/Button.vue";
    import OpenHours from "../../Generic/OpenHours.vue";

    @Component({
		filters: {
			limit: AbstractHighlight.limit
		},
		components: {
			Icon,
			Button,
			OpenHours
		}
	})
	export default class OfficelocationHighlight extends AbstractHighlight {

	}
