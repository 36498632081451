































    import {Component, Prop, Vue} from "vue-property-decorator";
    import Icon from "../../Generic/Icon.vue";
    import AbstractHighlight from "./AbstractHighlight";
    import Button from "../../Generic/Button.vue";

    @Component({
		filters: {
			limit: AbstractHighlight.limit
		},
		components: {
			Icon,
			Button
		}
	})
	export default class StaffHighlight extends AbstractHighlight {
		get mailLink() {
			return 'mailto:' + this.result.data.mail;
		}
	}
