var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "h1",
        { staticClass: "hyphenate" },
        [
          _c("Icon", { attrs: { itemName: "situation" } }),
          _vm._v(" "),
          _c("span", { domProps: { innerHTML: _vm._s(_vm.result.data.title) } })
        ],
        1
      ),
      _vm._v(" "),
      _vm.htmlContent
        ? _c("LimitedContent", { attrs: { "max-height": "150" } }, [
            _c("p", { domProps: { innerHTML: _vm._s(_vm.htmlContent) } })
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "Button",
        {
          attrs: {
            href: _vm.result.data.url,
            onClick: _vm.logAndOpen,
            additionalClasses: ["c-gufi-more-information"]
          }
        },
        [_vm._v(_vm._s(_vm.translate("SEARCH_MORE_INFORMATION")))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }