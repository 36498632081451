
















import {Component} from "vue-property-decorator";
import Icon from "../../Generic/Icon.vue";
import AbstractHighlight from "./AbstractHighlight";
import Button from "../../Generic/Button.vue";
import LimitedContent from "../../Generic/LimitedContent.vue";

@Component({
	filters: {
		limit: AbstractHighlight.limit
	},
	components: {
		Icon,
		Button,
		LimitedContent
	}
})
export default class FormHighlight extends AbstractHighlight {

}
