var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-gufi-preview-result-list" },
    [
      _c(
        "ul",
        _vm._l(_vm.results, function(result, index) {
          return _c("preview-result", {
            key: result._id,
            attrs: { result: result, isActive: result === _vm.activeResult },
            on: {
              onActiveResultChanged: _vm.onActiveResultChanged,
              onResultClicked: _vm.onResultClicked
            }
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "Button",
        {
          attrs: {
            href: _vm.listLink,
            type: "invert",
            additionalClasses: ["c-gufi-preview-result-rest"]
          }
        },
        [_vm._v(_vm._s(_vm.translate("SEARCH_ALL_RESULTS")))]
      ),
      _vm._v(" "),
      _vm.results.length > 0
        ? _c("search-stats", { attrs: { meta: _vm.meta } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }