













    import {Component, Prop, Vue} from "vue-property-decorator";
import {SearchResultItem} from "../../../services/SearchService";
    import Icon from "../../Generic/Icon.vue";
    import AbstractResult from "./AbstractResult";

    @Component({
		filters: {
			limit: ResourceResult.limit
		},
		components: {
			Icon
		}
	})
	export default class ResourceResult extends AbstractResult {

	}
