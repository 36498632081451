import Axios from 'axios';

export interface LogEntry {
	message: string,
	meta: object
}

export interface ExtendedLogEntry extends LogEntry {
	software: string,
	project: string,
	level: LogLevel,
}

export enum LogLevel {
	trace = "trace",
	notice = "notice",
	warning = "warning",
	error = "error",
	profile = "profile"
}

export default class LoggingService {
	private readonly endpoint: string;
	private readonly apiKey: string;
	private readonly software: string;
	private readonly project: string;

	constructor(endpoint: string, apiKey: string, software: string, project: string,) {
		this.endpoint = endpoint;
		this.apiKey = apiKey;
		this.software = software;
		this.project = project;
	}

	public trace(logEntry: LogEntry): Promise<Boolean> {
		return this.log(LogLevel.trace, logEntry);
	}

	public notice(logEntry: LogEntry): Promise<Boolean> {
		return this.log(LogLevel.notice, logEntry);
	}

	public warning(logEntry: LogEntry): Promise<Boolean> {
		return this.log(LogLevel.warning, logEntry);
	}

	public error(logEntry: LogEntry): Promise<Boolean> {
		return this.log(LogLevel.error, logEntry);
	}

	public profile(logEntry: LogEntry): Promise<Boolean> {
		return this.log(LogLevel.profile, logEntry);
	}

	public log(logLevel: LogLevel, logEntry: LogEntry): Promise<Boolean> {
		const extendedLogEntry = {
			software: this.software,
			project: this.project,
			level: logLevel,
			...logEntry
		};

		return this._log(extendedLogEntry);
	}

	protected _log(logEntry: ExtendedLogEntry): Promise<Boolean> {
		const axios = Axios.create();

		return axios
			.post(this.endpoint, logEntry, {
				headers: {
					"x-api-key": this.apiKey
				}
			})
			.then(response => response.data).then(response => {
				return true;
			}).catch(response => {
				console.error(response);
				return false;
			});
	}
}
