var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "c-gufi-searchform",
      class: { "c-gufi-search-hastext": _vm.queryHasText },
      on: { submit: _vm.submitForm }
    },
    [
      _c("search-bar", {
        attrs: { query: _vm.query },
        on: {
          onQueryChanged: _vm.onQueryChanged,
          onDoSearch: _vm.onDoSearch,
          onResetForm: _vm.onResetForm,
          changeActiveSuggestionDown: _vm.changeActiveSuggestionDown,
          changeActiveSuggestionUp: _vm.changeActiveSuggestionUp,
          resetActiveSuggestion: _vm.onResetActiveSuggestion
        }
      }),
      _vm._v(" "),
      _vm.showSuggestions
        ? _c("Suggest", {
            attrs: {
              activeSuggestion: _vm.activeSuggestion,
              query: _vm.query,
              searchService: _vm.searchService
            },
            on: {
              suggestionClicked: _vm.onSuggestionClicked,
              suggestionChanged: _vm.onSuggestionChanged
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.toolbarEnabled
        ? _c("content-type-bar", {
            attrs: { contentType: _vm.contentType },
            on: { onContentTypeChanged: _vm.onContentTypeChanged }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }