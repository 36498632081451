































import {Component, Prop, Vue} from "vue-property-decorator";
import SearchBar from "./components/Generic/SearchBar.vue";
import SearchService, {SearchMetaData, SearchResultItem, SuggestionResultItem} from "./services/SearchService";
import PreviewResultList from "./components/Preview/PreviewResultList.vue";
import Preview from "./components/Preview/Preview.vue";
import Overlay from "./components/Preview/Overlay.vue";
import ConfigService from "./services/ConfigService";
import PreviewsWrapper from "./components/Preview/PreviewsWrapper.vue";
import LoggingService from "./services/LoggingService";
import {Constants} from "./Constants";
import Translator from "./Translator";
import PreviewSuggestionList from "./components/Preview/PreviewSuggestionList.vue";
import SearchBarSuggestion from "./components/Preview/SearchBarSuggestion.vue";

const debounce = require('lodash.debounce');

@Component({
	components: {
		Overlay,
		Preview,
		PreviewResultList,
		SearchBar,
		SearchBarSuggestion,
		PreviewsWrapper,
		PreviewSuggestionList
	},
	props: {
		container: String,
		defaultQuery: String,
		config: Object
	}
})
export default class GufiPreview extends Vue {
	@Prop()
	private container: string;
	@Prop()
	private defaultQuery: string;
	@Prop()
	private config;
	@Prop({default: 8})
	private numberofpreviews: number;

	results: SearchResultItem[] = [];
	meta: SearchMetaData = {hits: 0, pages: 0, time: 0};

	suggestions: SuggestionResultItem[] = [];

	activeResult: SearchResultItem = null;

	query: string = "";
	showPreview: boolean = false;

	private debouncer = null;
	private showPreviewDebouncer = null;
	private hidePreviewDebouncer = null;

	private configService: ConfigService;
	private loggingService: LoggingService = new LoggingService(Constants.logging_endpoint, Constants.logging_apiKey, Constants.logging_softwareName, this.container);
	private searchService: SearchService = new SearchService(Constants.search_endpoint + this.container, this.config.key, this.loggingService);

	private openDirection: string = 'bottom';

	created() {
		this.debouncer = debounce(this.onDoSearch, 300);
		this.showPreviewDebouncer = debounce(() => this.showPreview = true, 400);
		this.hidePreviewDebouncer = debounce(() => this.showPreview = false, 400);

		this.configService = new ConfigService(this.config);

		if (this.config.language) {
			Translator.setLocale(this.config.language)
		}

		if (this.config.disableRetrieve) {
			this.searchService.disableRetrieve();
		}

		window.addEventListener('scroll', this.onWindowScroll);
	}

	mounted() {
		this.onWindowScroll();
	}

	destroyed() {
		window.removeEventListener('scroll', this.onWindowScroll);
	}

	get queryHasText() {
		return this.query.length > 0;
	}

	get wrapperClasses() {
		return {
			'c-gufi-preview-wrapper': true,
			'c-gufi-preview-wrapper-active': this.showPreview,
			'c-gufi-preview-wrapper-hasResults': this.results.length > 0,
			'c-gufi-preview-wrapper-open-direction-top': this.openDirection === "top",
			'c-gufi-preview-wrapper-open-direction-bottom': this.openDirection === "bottom",
		};
	}

	get listLink() {
		return (this.config.urls && this.config.urls.list) ? (this.config.urls.list + '?query=' + this.query) : '';
	}

	onDoSearch() {
		if (this.query.trim().length === 0) {
			return;
		}

		this.searchService.preview(this.query, this.numberofpreviews).then(result => {
				this.results = result.results;
				this.meta = result.meta;

				if (this.results.length > 0) {
					this.activeResult = this.results[0];
					this.showPreviewDebouncer();
				}
		});

		this.searchService.suggest(this.query).then(results => {
			this.suggestions = results.slice(0, 4);
		});
	}

	onResultClicked(data) {
		this.searchService.logClick(this.query, data.position, data.url).then(() => {
			location.href = data.url;
		});
	}

	submitForm(e: Event) {
		e.preventDefault();

		this.onDoSearch();
	}

	// If the user cancels via search bar 'x'
	onCancelSearch() {
		this.query = '';

		this.resetSearchForm();
	}

	resetSearchForm() {
		this.suggestions = [];
		this.results = [];
		this.meta = {hits: 0, pages: 0, time: 0};
	}

	showResultList() {
		location.href = this.listLink;
	}

	onQueryChanged(query) {
		this.query = query;

		if (this.query.trim().length > 0) {
			this.debouncer();
		} else {
			// The user empties the search bar
			this.resetSearchForm();
		}
	}

	onActiveResultChanged(activeResult) {
		this.activeResult = activeResult;
	}

	onMouseLeave() {
		this.hidePreviewDebouncer();
	}

	onMouseOver() {
		if (this.results.length > 0) {
			this.showPreviewDebouncer();
		}
	}

	onFocusOut() {
		this.hidePreviewDebouncer();
	}

	onFocusIn() {
		this.hidePreviewDebouncer.cancel();
	}

	onOverlayClicked() {
		this.showPreview = false;
	}

	onWindowScroll() {
		this.openDirection = (this.$el.getBoundingClientRect().top - window.innerHeight * 0.5 > 0) ? 'top' : 'bottom';
	}

	onKeyUp(e: KeyboardEvent) {
		if (e.code === 'Escape') {
			this.onFocusOut();
		}
	}
}
