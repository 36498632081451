var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-gufi-openHours" },
    _vm._l(_vm.openHours, function(openHour) {
      return _c(
        "div",
        { staticClass: "c-gufi-openHours-table" },
        [
          _c("div", [_vm._v("\n\t\t\tFür Sie geöffnet\n\t\t")]),
          _vm._v(" "),
          _vm._l(_vm.days, function(day, key) {
            return _vm.hasOpenHoursForDay(key, openHour)
              ? _c("div", [
                  _c("div", [
                    _c("h2", [
                      _vm._v("\n\t\t\t\t\t" + _vm._s(day.label) + "\n\t\t\t\t")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm.hasOpenHoursForMorning(key, openHour)
                      ? _c("div", [
                          _vm._v(
                            "\n\t\t\t\t\t" +
                              _vm._s(openHour[key + "_morning_from"]) +
                              "\n\t\t\t\t"
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm.hasOpenHoursForMorning(key, openHour)
                      ? _c("div", [_vm._v("\n\t\t\t\t\t-\n\t\t\t\t")])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm.hasOpenHoursForMorning(key, openHour)
                      ? _c("div", [
                          _vm._v(
                            "\n\t\t\t\t\t" +
                              _vm._s(openHour[key + "_morning_to"]) +
                              "\n\t\t\t\t"
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm.hasOpenHoursForMorning(key, openHour) &&
                    _vm.hasOpenHoursForAfternoon(key, openHour)
                      ? _c("div", [_vm._v("und\n\t\t\t\t")])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm.hasOpenHoursForAfternoon(key, openHour)
                      ? _c("div", [
                          _vm._v(
                            "\n\t\t\t\t\t" +
                              _vm._s(openHour[key + "_afternoon_from"]) +
                              "\n\t\t\t\t"
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm.hasOpenHoursForAfternoon(key, openHour)
                      ? _c("div", [_vm._v("\n\t\t\t\t\t-\n\t\t\t\t")])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm.hasOpenHoursForAfternoon(key, openHour)
                      ? _c("div", [
                          _vm._v(
                            "\n\t\t\t\t\t" +
                              _vm._s(openHour[key + "_afternoon_to"]) +
                              "\n\t\t\t\t"
                          )
                        ])
                      : _vm._e()
                  ])
                ])
              : _vm._e()
          })
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }