



















































import {Component, Vue, Prop} from "vue-property-decorator";
import Translator from "../../Translator";

@Component({})
export default class OpenHours extends Vue {
	@Prop({default: {}}) openHours: object;

	private days = {
		mon: {label: Translator.get('DAY_MON')},
		tue: {label: Translator.get('DAY_TUE')},
		wed: {label: Translator.get('DAY_WED')},
		thu: {label: Translator.get('DAY_THU')},
		fri: {label: Translator.get('DAY_FRI')},
		sat: {label: Translator.get('DAY_SAT')},
		sun: {label: Translator.get('DAY_SUN')},
	};

	hasOpenHoursForDay(day: string, openHour): boolean {
		return this.hasOpenHoursForMorning(day, openHour) || this.hasOpenHoursForAfternoon(day, openHour)
	}

	hasOpenHoursForMorning(day: string, openHour): boolean {
		return openHour[day + '_morning_from'];
	}

	hasOpenHoursForAfternoon(day: string, openHour): boolean {
		return openHour[day + '_afternoon_from'];
	}


}
