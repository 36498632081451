






























    import {Component} from "vue-property-decorator";
    import Icon from "../../Generic/Icon.vue";
    import Button from "../../Generic/Button.vue";
    import OpenHours from "../../Generic/OpenHours.vue";
    import AbstractPreview from "./AbstractPreview";

    @Component({
		components: {OpenHours, Icon, Button}
	})
	export default class OfficelocationPreview extends AbstractPreview {
		get classes() {
			return {
				'c-gufi-preview-officelocation-withImage': this.itemData.previewImage
			}
		}
	}
