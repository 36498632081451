var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    {
      class: _vm.resultClasses,
      attrs: { tabindex: "-1" },
      on: {
        mouseenter: function(e) {
          return e.target.classList.toggle("highlight")
        },
        mouseleave: function(e) {
          return e.target.classList.toggle("highlight")
        }
      }
    },
    [
      _c(_vm.resultComponentName, {
        tag: "component",
        attrs: { result: _vm.result },
        on: { onResultClicked: _vm.onResultClicked }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }