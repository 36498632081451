var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", {
    staticClass: "c-icon",
    class: _vm.itemIconClass,
    attrs: { title: _vm.itemTitle }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }