














    import {Component, Prop, Vue} from "vue-property-decorator";
    import SearchService, {SearchMetaData, SearchResultItem} from "../../services/SearchService";
    import Icon from "../Generic/Icon.vue";
    import Translator from "../../Translator";
    import FormHighlight from "./Highlight/FormHighlight.vue";
    import OfficelocationHighlight from "./Highlight/OfficelocationHighlight.vue";
    import ServiceHighlight from "./Highlight/ServiceHighlight.vue";
    import SituationHighlight from "./Highlight/SituationHighlight.vue";
    import StaffHighlight from "./Highlight/StaffHighlight.vue";

    @Component({
		directives: {},
		components: {
			Icon,
			FormHighlight,
			OfficelocationHighlight,
			ServiceHighlight,
			SituationHighlight,
			StaffHighlight,
		}
	})
	export default class PreviewResult extends Vue {
		@Prop() result: SearchResultItem;
		@Prop() searchService: SearchService;
		@Prop() meta: SearchMetaData;
		@Prop() isActive: boolean;

		translate(key) {
			return Translator.get(key)
		}

		get previewResultClasses() {
			return [
                'c-gufi-preview-result',
                (this.isActive) ? 'c-gufi-preview-result-active' : '',
                (this.result.tag) ? 'c-gufi-preview-result-withTag' : '',
                (this.isHighlight) ? 'c-gufi-preview-result-highlight' : '',
                (this.result._type !== "" ? ('c-gufi-preview-result-' + this.result._type) : "")
            ];
		}

		get isHighlight() {
			return this.result.position === 1 && !['resource', 'page'].includes(this.result._type);
		}

		get highlightComponentName() {
			return this.result._type + '-highlight';
		}

		onActiveResultChanged() {
			this.$emit('onActiveResultChanged', this.result);
		}

		logAndOpen(event) {
			event.preventDefault();

			const url = this.result.url;

			this.$emit('onResultClicked', {
				url: url,
				position: this.result.position
			});
		}

	}
