















    import {Component, Prop, Vue} from "vue-property-decorator";
    import Icon from "../../Generic/Icon.vue";
    import AbstractHighlight from "./AbstractHighlight";
    import Button from "../../Generic/Button.vue";
    import LimitedContent from "../../Generic/LimitedContent.vue";

    @Component({
		filters: {
			limit: AbstractHighlight.limit
		},
		components: {
			Icon,
			Button,
			LimitedContent
		}
	})
	export default class SituationHighlight extends AbstractHighlight {
		get htmlContent() {
			if (this.result.data.description) {
				return this.result.data.description;
			}

			if (this.result.data.info) {
				return this.result.data.info;
			}

			return this.result.snippets.join('... ');
		}
	}
