import 'promise-polyfill/src/polyfill';
import 'polyfill-array-includes';
import 'es7-object-polyfill';

import Vue from "vue";
import GufiList from "./GufiList.vue";
import GufiPreview from "./GufiPreview.vue";

Vue.component('gufi-list', GufiList);
Vue.component('gufi-preview', GufiPreview);


export default (config: object) => {
	Object.values(config).forEach(partial => {
		if (partial) {
			if (!partial['selector']) {
				throw new Error('No partial selector provided!');
			}

			new Vue({
				el: partial['selector'],
				data: {
					config: partial['config'] || {}
				}
			});
		}
	});
}
